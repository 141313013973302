import gql from 'graphql-tag'
import articleFields from './fragments/article-fields.gql'
import { PUBLISHED_POST_STATES } from '~/constants/content'

const buildFeaturedArticlesByTagsQuery = ({
  statuses = PUBLISHED_POST_STATES,
}) => {
  return gql`
    query getFeaturedArticlesByTags($first: Int, $tags: [String]) {
      articles: memberships(
        first: $first
        where: {
          stati: [${statuses}]
          orderby: { field: DATE, order: DESC }
          taxQuery: {
            relation: AND
            taxArray: [
              { terms: $tags, taxonomy: TAG, operator: AND, field: SLUG },
              {
                terms: ["public"]
                taxonomy: CATEGORY
                operator: NOT_IN
                field: SLUG
              }
            ]
          }
          showOnlyFeaturedPosts: true
        }
      ) {
        nodes {
          ...articleFields
          memberships {
            thumbnailSquare {
              node {
                sourceUrl
              }
            }
          }
        }
      }
    }
    ${articleFields}
  `
}

export default buildFeaturedArticlesByTagsQuery
