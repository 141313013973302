import gql from 'graphql-tag'
import articleFields from './fragments/article-fields.gql'
import videoFields from './fragments/video-fields.gql'
import { PUBLISHED_POST_STATES } from '~/constants/content'

const buildContentByCategoriesQuery = ({
  statuses = PUBLISHED_POST_STATES,
}) => {
  return gql`
    query getContentByCategories(
      $first: Int!
      $after: String
      $categories: [String]!
      $excluded: [ID]
    ) {
      contentNodes(
        first: $first
        after: $after
        where: {
          stati: [${statuses}]
          orderby: { field: DATE, order: DESC }
          contentTypes: [VIDEO, MEMBERSHIP]
          taxQuery: {
            relation: AND
            taxArray: [
              {
                terms: $categories
                taxonomy: CATEGORY
                operator: AND
                field: SLUG
              },
              {
                terms: ["public"]
                taxonomy: CATEGORY
                operator: NOT_IN
                field: SLUG
              }
            ]
          }
          notIn: $excluded
        }
      ) {
        pageInfo {
          total
        }
        edges {
          node {
            # Generic post type fields
            __typename

            # Load this values only for Membership CPT
            ... on Membership {
              ... articleFields
            }

            # Load this values only for Video CPT
            ... on Video {
              ... videoFields
            }
          }
        }
      }
    }
    ${articleFields}
    ${videoFields}
  `
}

export default buildContentByCategoriesQuery
