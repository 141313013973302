import gql from 'graphql-tag'
import articleFields from './fragments/article-fields.gql'
import { PUBLISHED_POST_STATES } from '~/constants/content'

const buildFeaturedArticlesByCategoriesQuery = ({
  statuses = PUBLISHED_POST_STATES,
}) => {
  return gql`
    query getFeaturedArticlesByCategories($first: Int, $categories: [String]) {
      articles: memberships(
        first: $first
        where: {
          stati: [${statuses}]
          taxQuery: {
            relation: AND
            taxArray: [
              {
                terms: $categories
                taxonomy: CATEGORY
                operator: AND
                field: SLUG
              },
              {
                terms: ["public"]
                taxonomy: CATEGORY
                operator: NOT_IN
                field: SLUG
              }
            ]
          }
          showOnlyFeaturedPosts: true
        }
      ) {
        nodes {
          ... articleFields
        }
      }
    }
    ${articleFields}
  `
}

export default buildFeaturedArticlesByCategoriesQuery
