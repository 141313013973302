export const LEARN_MORE_LINKS = {
  'forecast-lithium': '/lithium/data-reports/forecast/',
  'forecast-battery-database': '/lithium-ion-batteries/data-reports/forecast/',
  'forecast-cobalt': '/cobalt/data-reports/forecast/',
  'forecast-nickel': '/nickel/data-reports/forecast/',
  'forecast-natural-graphite': '/natural-graphite/data-reports/forecast/',
  'forecast-synthetic-graphite': '/synthetic-graphite/data-reports/forecast/',
  'forecast-solid-state': '/solid-state/data-reports/forecast/',
  'forecast-anode': '/anode/data-reports/forecast/',
  'forecast-cathode': '/cathode/data-reports/forecast/',
  'forecast-recycling': '/recycling/data-reports/forecast/',
  'forecast-silicon-anode': '/silicon-anode/data-reports/forecast/',
  'forecast-manganese': '/manganese/data-reports/forecast/',
  'forecast-phosphate': '/phosphate/data-reports/forecast/',
  'forecast-fluorspar': '/fluorspar/data-reports/forecast/',
  'forecast-rare-earths': '/rare-earths/data-reports/forecast/',
  subscriber: '/about',

  'market-assessment-gigafactory':
    '/lithium-ion-batteries/data-reports/market-assessments/',
  'market-assessment-precursor': '/cathode/data-reports/market-assessments/',
  'market-assessment-sodium-ion-battery':
    '/sodium-ion-batteries/data-reports/market-assessments/',

  'pricing-black-mass': '/recycling/recycling-prices/table/',
  'pricing-lithium': '/lithium/data-reports/market-assessments/',
  'pricing-cobalt': '/cobalt/data-reports/market-assessments/',
  'pricing-nickel': '/nickel/data-reports/market-assessments/',
  'pricing-natural-graphite':
    '/natural-graphite/data-reports/market-assessments/',
  'pricing-synthetic-graphite':
    '/synthetic-graphite/data-reports/market-assessments/',
  'pricing-battery-cell':
    '/lithium-ion-batteries/data-reports/price-assessments/',
  'pricing-anode': '/anode/data-reports/market-assessments/',
  'pricing-cathode': '/cathode/data-reports/market-assessments/',
  'pricing-rare-earths': '/rare-earths/data-reports/market-assessments/',
  'pricing-all-prices': '/price-dashboard/',

  'esg-graphite': '/natural-graphite/sustainability/overview/',
  'esg-lithium': '/lithium/sustainability/overview/',
  'esg-cobalt': '/cobalt/sustainability/overview/',
  'esg-nickel': '/nickel/sustainability/overview/',
  'esg-battery-emissions-analyser': '/battery-emissions-analyser/',
  'esg-rare-earths': '/rare-earths/sustainability/overview/',
}

export const SUBSCRIPTIONS = {
  Forecast: [
    {
      name: 'Lithium',
      slug: 'forecast-lithium',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Lithium Ion Batteries',
      slug: 'forecast-battery-database',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Cobalt',
      slug: 'forecast-cobalt',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Nickel',
      slug: 'forecast-nickel',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Natural Graphite',
      slug: 'forecast-natural-graphite',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Synthetic Graphite',
      slug: 'forecast-synthetic-graphite',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Silicon Anode',
      slug: 'forecast-silicon-anode',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Manganese',
      slug: 'forecast-manganese',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Phosphate',
      slug: 'forecast-phosphate',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Fluorspar',
      slug: 'forecast-fluorspar',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Solid State',
      slug: 'forecast-solid-state',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Anode',
      slug: 'forecast-anode',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Cathode',
      slug: 'forecast-cathode',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Recycling',
      slug: 'forecast-recycling',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Rare Earths',
      slug: 'forecast-rare-earths',
      status: 'null',
      expirationDate: 'none',
    },
  ],
  Source: [
    {
      name: 'Subscriber',
      slug: 'subscriber',
      status: 'null',
      expirationDate: 'none',
    },
  ],
  'Market Assessments': [
    {
      name: 'Gigafactory Assessment',
      slug: 'market-assessment-gigafactory',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Precursor Assessment',
      slug: 'market-assessment-precursor',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Sodium ion Batteries',
      slug: 'market-assessment-sodium-ion-battery',
      status: 'null',
      expirationDate: 'none',
    },
  ],
  'Price Assessments': [
    {
      name: 'Lithium',
      slug: 'pricing-lithium',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Cobalt',
      slug: 'pricing-cobalt',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Nickel',
      slug: 'pricing-nickel',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Natural Graphite',
      slug: 'pricing-natural-graphite',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Synthetic Graphite',
      slug: 'pricing-synthetic-graphite',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Lithium Ion Batteries',
      slug: 'pricing-battery-cell',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Anode',
      slug: 'pricing-anode',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Cathode',
      slug: 'pricing-cathode',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Black Mass',
      slug: 'pricing-black-mass',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Rare Earths',
      slug: 'pricing-rare-earths',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'All prices',
      slug: 'pricing-all-prices',
      status: 'null',
      expirationDate: 'none',
    },
  ],
  ESG: [
    {
      name: 'Lithium',
      slug: 'esg-lithium',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Cobalt',
      slug: 'esg-cobalt',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Nickel',
      slug: 'esg-nickel',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Graphite',
      slug: 'esg-graphite',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Battery Emissions Analyser',
      slug: 'esg-battery-emissions-analyser',
      status: 'null',
      expirationDate: 'none',
    },
    {
      name: 'Rare Earths',
      slug: 'esg-rare-earths',
      status: 'null',
      expirationDate: 'none',
    },
  ],
}

export const SUBSCRIPTIONS_SLUGS: string[] = Object.values(SUBSCRIPTIONS)
  .flat()
  .map((sub) => sub.slug)

export const SUBSCRIPTIONS_BY_SLUGS = Object.values(SUBSCRIPTIONS)
  .flat()
  .reduce((acc, sub) => ({ ...acc, [sub.slug]: sub }), {})
