import gql from 'graphql-tag'
import articleFields from './fragments/article-fields.gql'
import { QueryBuilder } from '@/models/graphql'
import { NON_PUBLISHED_POST_STATES } from '~/constants/content'

const buildArticlesByCategoriesQuery: QueryBuilder = ({
  statuses = NON_PUBLISHED_POST_STATES,
  operator = 'AND',
}) => {
  return gql`
    query getArticlesByCategories(
      $first: Int
      $after: String
      $categories: [String]
      $excluded: [ID]
    ) {
      articles: memberships(
        first: $first
        after: $after
        where: {
          stati: [${statuses}]
          taxQuery: {
            relation: AND
            taxArray: [
              {
                terms: $categories
                taxonomy: CATEGORY
                operator: ${operator}
                field: SLUG
              },
              {
                terms: ["public"]
                taxonomy: CATEGORY
                operator: NOT_IN
                field: SLUG
              }
            ]
          }
          notIn: $excluded
        }
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ... articleFields
        }
      }
    }
    ${articleFields}
  `
}

export default buildArticlesByCategoriesQuery
