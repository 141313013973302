import { snakeCaseToCamelCase } from '@/utils/string'

export function keysToCamelCase(obj: Record<string, any>): Record<string, any> {
  const newObj = {}

  for (const key in obj) {
    newObj[snakeCaseToCamelCase(key)] = obj[key]
  }

  return newObj
}
