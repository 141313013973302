import gql from 'graphql-tag'
import articleFields from './fragments/article-fields.gql'
import { PUBLISHED_POST_STATES } from '~/constants/content'

const buildFeaturedArticlesQuery = ({ statuses = PUBLISHED_POST_STATES }) => {
  return gql`
    query getFeaturedArticles($first: Int) {
      articles: memberships(
        first: $first,
        where: {
          stati: [${statuses}]
          showOnlyFeaturedPosts: true,
          taxQuery: {
            taxArray: [
              {
                terms: ["public"]
                taxonomy: CATEGORY
                operator: NOT_IN
                field: SLUG
              }
            ]
          }
        }
      ) {
        nodes {
          ...articleFields
        }
      }
    }
    ${articleFields}
  `
}

export default buildFeaturedArticlesQuery
