export const allMediums = [
  { value: 'membership', label: 'Articles' },
  { value: 'video', label: 'Videos' },
  { value: 'magazine', label: 'Magazines' },
  { value: 'presentation', label: 'Presentations' },
]

export const NON_PUBLISHED_POST_STATES = ['DRAFT', 'FUTURE']
export const PUBLISHED_POST_STATES = ['PUBLISH']

export const CHART_ARTICLE_TAG = 'data-visualisation'
export const BENCHMARK_PRESENTATION_CATEGORY = 'benchmark-mineral-intelligence'

export const POST_TYPES = ['membership', 'magazine', 'video', 'presentation']
