import { Buffer } from 'buffer'

export function delayed<T>(callback, ms: number): Promise<T> {
  return new Promise((resolve) => setTimeout(() => resolve(callback), ms))
}

export const encodeObject = (data: object) => {
  return Buffer.from(JSON.stringify(data), 'binary').toString('base64')
}

export const decodeObject = (data: string) => {
  return JSON.parse(Buffer.from(data, 'base64').toString('binary'))
}
