import gql from 'graphql-tag'
import articleFields from './fragments/article-fields.gql'

export default () => gql`
  query getArticleDetailById($id: ID!) {
    article: membership(id: $id, idType: DATABASE_ID) {
      ...articleFields
    }
  }
  ${articleFields}
`
