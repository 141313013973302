import gql from 'graphql-tag'
import articleBaseFields from './fragments/article-base-fields.gql'
import { PUBLISHED_POST_STATES } from '~/constants/content'

const buildArticlesBaseQuery = ({ statuses = PUBLISHED_POST_STATES }) => {
  return gql`
    query getArticles(
      $first: Int
      $after: String
      $includeFeatured: Boolean
      $excluded: [ID]
    ) {
      articles: memberships(
        first: $first
        after: $after
        where: {
          stati: [${statuses}]
          showOnlyFeaturedPosts: $includeFeatured
          notIn: $excluded,
          taxQuery: {
            taxArray: [
              {
                terms: ["public"]
                taxonomy: CATEGORY
                operator: NOT_IN
                field: SLUG
              }
            ]
          }
        }
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ... articleBaseFields
        }
      }
    }
    ${articleBaseFields}
  `
}

export default buildArticlesBaseQuery
