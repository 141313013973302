import { defineStore } from 'pinia'
import { Article } from '@/models/content'

export const useDataVisualisationStore = defineStore({
  id: 'data-visualisation-store',

  state: () =>
    ({
      displayedArticles: [],
    } as {
      displayedArticles: Article[]
    }),

  actions: {
    pushArticles(articles: Article[]) {
      this.displayedArticles.push(...articles)
    },

    cleanArticles() {
      this.displayedArticles = []
    },
  },
})
