import gql from 'graphql-tag'

const buildVideoWithSeo = ({ slug }: { slug: string }) => {
  return gql`
    query getVideoWithSeo {
      video(id: "${slug}", idType: SLUG) {
        title
        videos {
          thumbnail {
            node {
              mediaItemUrl
            }
          }
        }
      }
    }
  `
}

export default buildVideoWithSeo
