import {
  PUBLISHED_POST_STATES,
  NON_PUBLISHED_POST_STATES,
} from './../constants/content'
import buildVideosQuery from '@/queries/videos'
import buildArticlesBaseQuery from '@/queries/articles-base'
import buildArticlesSimpleQuery from '@/queries/articles-simple'
import buildVideosByCategoriesQuery from '@/queries/videos-by-categories'
import buildArticlesByCategoriesQuery from '@/queries/articles-by-categories'
import buildArticlesByTagsQuery from '@/queries/articles-by-tags'
import buildArticleWithSeo from '@/queries/articles-with-seo'
import buildVideoWithSeo from '@/queries/video-with-seo'
import buildMostRecentFeaturedVideosQuery from '@/queries/featured-videos'
import buildMostRecentFeaturedVideoByCategoryQuery from '@/queries/featured-video-by-category'
import buildFeaturedArticlesQuery from '@/queries/featured-articles'
import buildFeaturedArticlesByCategoriesQuery from '@/queries/featured-articles-by-categories'
import buildFeaturedArticlesByTagsQuery from '@/queries/featured-articles-by-tags'
import getFeaturedSubCategory from '@/queries/featured-subcategory.gql'
import featuredSection from '@/queries/featured-section.gql'
import buildContentByCategoriesQuery from '@/queries/contents-by-categories'
import buildContentsByTagsQuery from '@/queries/contents-by-tags'
import buildVideoDetailByIdQuery from '@/queries/video-detail-by-id'
import buildVideoDetailBySlugQuery from '@/queries/video-detail-by-slug'
import buildArticleDetailBySlugQuery from '@/queries/article-detail-by-slug'
import buildArticleDetailByIdQuery from '@/queries/article-detail-by-id'
import { Article, Video } from '@/models/content'
import {
  getAdaptedVideos,
  getAdaptedVideo,
  getAdaptedArticles,
  getAdaptedArticle,
  getAdaptedFeaturedSubcategory,
  getAdaptedContents,
  getAdaptedFeaturedCategoryData,
} from '@/utils/adapters'
import allCategories from '@/constants/categories'
import buildArticlesAndVideosQuery from '@/queries/articles-and-videos'

const MAX_ITEMS_PER_QUERY = 12

export default defineNuxtPlugin(({ $apollo, $config }) => {
  const apolloClient = $config.isPreviewSite
    ? $apollo.privateClient
    : $apollo.publicClient

  const getStatuses = () => {
    const postStates = [...PUBLISHED_POST_STATES]

    if ($config.isPreviewSite) {
      postStates.push(...NON_PUBLISHED_POST_STATES)
    }

    return postStates
  }

  return {
    provide: {
      graphqlClient: {
        async getVideos({
          after = null,
          itemsPerQuery = MAX_ITEMS_PER_QUERY,
          search = '',
        } = {}) {
          const { data } = await apolloClient.query({
            query: buildVideosQuery({ statuses: getStatuses() }),
            variables: {
              first: itemsPerQuery,
              after,
              search,
            },
          })

          const { hasNextPage, endCursor } = data.videos.pageInfo
          const videos = getAdaptedVideos(data)

          return {
            data: videos,
            hasNextPage,
            endCursor,
          }
        },

        async getVideosByCategories({
          after = null,
          categories = [],
          events = [],
          itemsPerQuery = MAX_ITEMS_PER_QUERY,
          search = '',
        }) {
          const { data } = await apolloClient.query({
            query: buildVideosByCategoriesQuery({ statuses: getStatuses() }),
            variables: {
              first: itemsPerQuery,
              after,
              categories: categories.length ? categories : allCategories,
              events,
              search,
            },
          })

          const { hasNextPage, endCursor, total } = data.videos.pageInfo
          const videos = getAdaptedVideos(data)

          return {
            data: videos,
            hasNextPage,
            endCursor,
            total,
          }
        },

        async getFeaturedVideos({
          after = null,
          itemsPerQuery = MAX_ITEMS_PER_QUERY,
        } = {}) {
          const { data } = await apolloClient.query({
            query: buildMostRecentFeaturedVideosQuery({
              statuses: getStatuses(),
            }),
            variables: {
              first: itemsPerQuery,
              after,
            },
          })
          const videos = getAdaptedVideos(data)
          return videos.length ? videos : []
        },

        async getFeaturedVideo(): Promise<Video> | null {
          const videos = await this.getFeaturedVideos({
            itemsPerQuery: 1,
          })
          return videos.length ? videos[0] : null
        },

        async getArticlesByTags({
          tags = [],
          itemsPerQuery = MAX_ITEMS_PER_QUERY,
          excluded = [],
        }) {
          const { data } = await apolloClient.query({
            query: buildArticlesByTagsQuery({
              operator: 'AND',
              statuses: getStatuses(),
            }),
            variables: { tags, first: itemsPerQuery, excluded },
          })

          return getAdaptedArticles(data)
        },

        async getFeaturedArticlesByTags({
          tags = [],
          itemsPerQuery = MAX_ITEMS_PER_QUERY,
        }) {
          const { data } = await apolloClient.query({
            query: buildFeaturedArticlesByTagsQuery({
              statuses: getStatuses(),
            }),
            variables: { tags, first: itemsPerQuery },
          })

          return getAdaptedArticles(data)
        },

        async getFeaturedVideoByCategory({ category }: { category: string }) {
          const { data } = await apolloClient.query({
            query: buildMostRecentFeaturedVideoByCategoryQuery({
              statuses: getStatuses(),
            }),
            variables: {
              category,
            },
          })
          const video = getAdaptedVideos(data)
          return video.length ? video[0] : null
        },

        async getVideoDetail({ slug }: { slug: string }) {
          const hasSlug = slug.match(/[a-zA-Z]/)
          const isDraft = !hasSlug

          if (isDraft && !$config.isPreviewSite) {
            return null
          }

          const { data } = hasSlug
            ? await $apollo.getClient().query({
                query: buildVideoDetailBySlugQuery(),
                variables: {
                  slug,
                },
              })
            : await $apollo.getClient().query({
                query: buildVideoDetailByIdQuery(),
                variables: {
                  id: slug,
                },
              })

          if (!data.video) return null

          const video = getAdaptedVideo(data)
          const categories = data.video.categories.nodes.map(
            (category: { name: string }) => category.name,
          )

          const { data: relatedData } = await apolloClient.query({
            query: buildVideosByCategoriesQuery({ statuses: getStatuses() }),
            variables: {
              first: MAX_ITEMS_PER_QUERY,
              categories,
              events: [],
            },
          })

          video.relatedVideos = getAdaptedVideos(relatedData)

          return video
        },

        async getArticlesAndVideos({
          itemsPerQuery = MAX_ITEMS_PER_QUERY,
          excluded = [],
        } = {}) {
          const { data } = await apolloClient.query({
            query: buildArticlesAndVideosQuery({ statuses: getStatuses() }),
            variables: {
              first: itemsPerQuery,
              excluded,
            },
          })

          const videos = getAdaptedVideos(data)
          const articles = getAdaptedArticles(data)
          const sorted = [...articles, ...videos]
            .sort(
              (first, second) =>
                new Date(second.date).getTime() -
                new Date(first.date).getTime(),
            )
            .slice(0, itemsPerQuery)

          return {
            data: sorted,
          }
        },

        async getArticles({
          after = null,
          itemsPerQuery = MAX_ITEMS_PER_QUERY,
          includeFeatured = false,
          excluded = [],
          detailed = true,
        } = {}) {
          const query = detailed
            ? buildArticlesBaseQuery({ statuses: getStatuses() })
            : buildArticlesSimpleQuery({
                statuses: getStatuses(),
              })
          const { data } = await apolloClient.query({
            query,
            variables: {
              first: itemsPerQuery,
              after,
              includeFeatured,
              excluded,
            },
          })

          const { hasNextPage, endCursor } = data.articles.pageInfo
          const articles = getAdaptedArticles(data)

          return {
            data: articles,
            hasNextPage,
            endCursor,
          }
        },

        async getArticleDetail({ slug }: { slug: string | number }) {
          const hasSlug = String(slug).match(/[a-zA-Z]/)
          const isDraft = !hasSlug

          if (isDraft && !$config.isPreviewSite) {
            return null
          }

          const { data } = hasSlug
            ? await $apollo.getClient().query({
                query: buildArticleDetailBySlugQuery(),
                variables: {
                  slug,
                },
              })
            : await $apollo.getClient().query({
                query: buildArticleDetailByIdQuery(),
                variables: {
                  id: slug,
                },
              })

          if (!data.article) return null

          const article = getAdaptedArticle(data)
          const categories = data.article.categories.nodes.map(
            (category: { name: string }) => category.name,
          )

          const { data: relatedData } = await apolloClient.query({
            query: buildArticlesByCategoriesQuery({
              operator: 'IN',
              statuses: getStatuses(),
            }),
            variables: {
              first: MAX_ITEMS_PER_QUERY,
              categories,
              excluded: [article.id],
            },
          })

          article.relatedArticles = getAdaptedArticles(relatedData)

          return article
        },

        async getArticlesByCategories({
          after = null,
          categories = [],
          itemsPerQuery = MAX_ITEMS_PER_QUERY,
          excluded = [],
        }) {
          const { data } = await apolloClient.query({
            query: buildArticlesByCategoriesQuery({
              operator: 'AND',
              statuses: getStatuses(),
            }),
            variables: {
              first: itemsPerQuery,
              after,
              categories,
              excluded,
            },
          })

          const { hasNextPage, endCursor } = data.articles.pageInfo
          const articles = getAdaptedArticles(data)

          return {
            data: articles,
            hasNextPage,
            endCursor,
          }
        },

        async getFeaturedArticles({
          itemsPerQuery,
        }: {
          itemsPerQuery: number
        }) {
          const { data } = await apolloClient.query({
            query: buildFeaturedArticlesQuery({ statuses: getStatuses() }),
            variables: {
              first: itemsPerQuery,
            },
          })

          const articles = getAdaptedArticles(data)

          const missingArticles = itemsPerQuery - articles.length

          if (missingArticles) {
            const { data: offsetArticles } = await this.getArticles({
              itemsPerQuery: missingArticles,
              excluded: articles.map((article) => article.id) as never[],
            })
            articles.push(...offsetArticles)
          }

          return articles
        },

        async getFeaturedArticlesByCategories({
          itemsPerQuery = 10,
          categories = [],
        }) {
          const { data } = await apolloClient.query({
            query: buildFeaturedArticlesByCategoriesQuery({
              statuses: getStatuses(),
            }),
            variables: {
              first: itemsPerQuery,
              categories,
            },
          })

          const articles = getAdaptedArticles(data)

          const missingArticles = itemsPerQuery - articles.length

          if (missingArticles) {
            const { data: offsetArticles } = await this.getArticlesByCategories(
              {
                itemsPerQuery: missingArticles,
                categories,
                excluded: [...articles.map((article) => article.id)] as never[],
              },
            )
            articles.push(...offsetArticles)
          }

          return articles
        },

        async getFeaturedSubcategoryArticles({
          category,
          itemsPerQuery,
          excluded = [],
        }: {
          category: string
          itemsPerQuery: number
          excluded?: never[]
        }) {
          const { data } = await apolloClient.query({
            query: getFeaturedSubCategory,
            variables: {
              first: itemsPerQuery,
              category,
            },
          })

          const featuredSubcategory = getAdaptedFeaturedSubcategory(data)

          const articles: Article[] = []

          if (featuredSubcategory) {
            const { data } = await this.getArticlesByCategories({
              itemsPerQuery,
              categories: [category, featuredSubcategory] as never[],
              excluded,
            })

            articles.push(...data)
          }

          return articles
        },

        async getFeaturedSection(category: string) {
          return getAdaptedFeaturedCategoryData(
            await apolloClient.query({
              query: featuredSection,
              variables: {
                category,
              },
            }),
          )
        },

        async getContentsByCategories({
          categories = [],
          itemsPerQuery = MAX_ITEMS_PER_QUERY,
          excluded = [],
        }) {
          const { data } = await apolloClient.query({
            query: buildContentByCategoriesQuery({ statuses: getStatuses() }),
            variables: {
              first: itemsPerQuery,
              categories,
              excluded,
            },
          })

          return {
            total: data.contentNodes.pageInfo.total,
            data: getAdaptedContents(data),
          }
        },

        async getContentsByTags({
          tags = [],
          itemsPerQuery = MAX_ITEMS_PER_QUERY,
          excluded = [],
        }) {
          const { data } = await apolloClient.query({
            query: buildContentsByTagsQuery({ statuses: getStatuses() }),
            variables: {
              first: itemsPerQuery,
              tags,
              excluded,
            },
          })

          return {
            total: data.contentNodes.pageInfo.total,
            data: getAdaptedContents(data),
          }
        },

        async getSeoInformation(slug: string) {
          const { data } = await apolloClient.query({
            query: buildArticleWithSeo({ slug }),
          })

          return data
        },

        async getVideoSeoInformation(slug: string) {
          const { data } = await apolloClient.query({
            query: buildVideoWithSeo({ slug }),
          })

          return data
        },
      },
    },
  }
})
