import gql from 'graphql-tag'
import videoFields from './fragments/video-fields.gql'

export default () => gql`
  query getVideoDetailBySlug($slug: ID!) {
    video(id: $slug, idType: SLUG) {
      ...videoFields
    }
  }
  ${videoFields}
`
