import { defineStore } from 'pinia'

const MOBILE_MENU = 'mobileMenu'
const REQUEST_A_TRIAL = 'requestATrial'

const MODALS_AND_MENUS = {
  login: false,
  subscribe: false,
  mobileMenu: false,
  updateRequest: false,
  changePassword: false,
  forgotPassword: false,
  accountMenu: false,
  requestATrial: false,
  imageLightbox: false,
  expiredTrial: false,
  renewMembership: false,
  noSourceSubscription: false,
  downloadSpecialIssue: false,
}

export const useModalStore = defineStore({
  id: 'modal-store',

  state: () => {
    return {
      imageSrc: '',
      loginRedirectionPath: null,
      ...MODALS_AND_MENUS,
    }
  },

  actions: {
    open(modal: string, imageSrc?: string) {
      this[modal] = true

      for (const _modal in MODALS_AND_MENUS)
        if (_modal !== modal) this.close(_modal)

      this.loginRedirectionPath = null
      if (imageSrc) {
        this.imageSrc = imageSrc
      }
    },

    close(modal: string) {
      this[modal] = false
      this.imageSrc = ''
    },

    toggle(modal: string) {
      this[modal] = !this[modal]
    },

    reset() {
      for (const _modal in MODALS_AND_MENUS) this.close(_modal)
      this.loginRedirectionPath = null
    },

    isMobileMenuActive() {
      return this[MOBILE_MENU] === true
    },

    isRequestATrialActive() {
      return this[REQUEST_A_TRIAL] === true
    },
  },

  getters: {
    getImageSrc: (state) => state.imageSrc,
  },
})
