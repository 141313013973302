import gql from 'graphql-tag'
import videoFields from './fragments/video-fields.gql'
import { PUBLISHED_POST_STATES } from '~/constants/content'

const buildVideosQuery = ({ statuses = PUBLISHED_POST_STATES }) => {
  return gql`
    query getVideos(
      $first: Int
      $after: String
      $search: String
    ) {
      videos(
        first: $first
        after: $after
        where: { 
          stati: [${statuses}]
          search: $search
          orderby: { field: DATE, order: DESC }
        }
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ... videoFields
        }
      }
    }
    ${videoFields}
  `
}

export default buildVideosQuery
