import Player from '@vimeo/player'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      vimeo: {
        init(
          element: HTMLIFrameElement | HTMLElement | string,
          options?: Player.Options,
        ) {
          return new Player(element, options)
        },
      },
    },
  }
})
