import gql from 'graphql-tag'
import videoFields from './fragments/video-fields.gql'
import { PUBLISHED_POST_STATES } from '~/constants/content'

const buildMostRecentFeaturedVideosQuery = ({
  statuses = PUBLISHED_POST_STATES,
}) => {
  return gql`
    query getMostRecentFeaturedVideos($first: Int, $after: String) {
      videos(
        first: $first
        after: $after
        where: {
          stati: [${statuses}]
          orderby: { field: DATE, order: DESC }
          showOnlyFeaturedPosts: true
        }
      ) {
        nodes {
          ... videoFields
        }
      }
    }
    ${videoFields}
  `
}

export default buildMostRecentFeaturedVideosQuery
