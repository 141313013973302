import { useMembershipStore } from '@/stores/membership'

export default defineNuxtPlugin(({ $restClient }) => {
  const membershipStore = useMembershipStore()

  const loadMembershipStatuses = async () => {
    const membershipStatuses = await $restClient.getMembershipStatuses({
      slugs: ['subscriber', 'trial'],
    })

    membershipStore.setMembershipStatuses(membershipStatuses)
  }

  return {
    provide: {
      membership: {
        loadMembershipStatuses,
      },
    },
  }
})
