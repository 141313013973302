import { useContentStore } from '@/stores/content'

export default defineNuxtPlugin(() => {
  const content = useContentStore()

  return {
    provide: {
      content,
    },
  }
})
