import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/router.mjs";
import node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import plugins_cloudinary_ts_SEKWrTzwvn from "/opt/buildhome/repo/plugins/cloudinary.ts";
import plugins_content_ts_CsGsOPNIRB from "/opt/buildhome/repo/plugins/content.ts";
import plugins_cookies_ts_rCS2psPm8j from "/opt/buildhome/repo/plugins/cookies.ts";
import plugins_dummy_model_ts_ItnpozlIRX from "/opt/buildhome/repo/plugins/dummy-model.ts";
import plugins_google_analytics_client_ts_KruZkwwzId from "/opt/buildhome/repo/plugins/google-analytics.client.ts";
import plugins_hubspot_identity_client_ts_uKz9KByQo6 from "/opt/buildhome/repo/plugins/hubspot-identity.client.ts";
import plugins_modal_ts_UEFnUXzgTX from "/opt/buildhome/repo/plugins/modal.ts";
import plugins_scroll_reset_ts_M4VQ40sLHo from "/opt/buildhome/repo/plugins/scroll-reset.ts";
import plugins_sentry_client_config_ts_o5jjUx2pcO from "/opt/buildhome/repo/plugins/sentry.client.config.ts";
import plugins_seo_manager_ts_WfCGakEskd from "/opt/buildhome/repo/plugins/seo-manager.ts";
import plugins_theme_ts_zupea9J243 from "/opt/buildhome/repo/plugins/theme.ts";
import plugins_token_ts_NCWuir2H2e from "/opt/buildhome/repo/plugins/token.ts";
import plugins_vee_validate_ts_KcKlKmvCrJ from "/opt/buildhome/repo/plugins/vee-validate.ts";
import plugins_vimeo_client_ts_BFAm9LGErL from "/opt/buildhome/repo/plugins/vimeo.client.ts";
import plugins_y_rest_client_ts_ouPNQxsruT from "/opt/buildhome/repo/plugins/y.rest-client.ts";
import plugins_ya_membership_ts_oqaQS7MApt from "/opt/buildhome/repo/plugins/ya.membership.ts";
import plugins_z_apollo_ts_kElVZx10q2 from "/opt/buildhome/repo/plugins/z.apollo.ts";
import plugins_z_app_version_watcher_ts_cqHyqBLNWq from "/opt/buildhome/repo/plugins/z.app-version-watcher.ts";
import plugins_z_auth_ts_BqBYggCFPK from "/opt/buildhome/repo/plugins/z.auth.ts";
import plugins_z_graphql_client_ts_5cZ6csbNhp from "/opt/buildhome/repo/plugins/z.graphql-client.ts";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq,
  plugins_cloudinary_ts_SEKWrTzwvn,
  plugins_content_ts_CsGsOPNIRB,
  plugins_cookies_ts_rCS2psPm8j,
  plugins_dummy_model_ts_ItnpozlIRX,
  plugins_google_analytics_client_ts_KruZkwwzId,
  plugins_hubspot_identity_client_ts_uKz9KByQo6,
  plugins_modal_ts_UEFnUXzgTX,
  plugins_scroll_reset_ts_M4VQ40sLHo,
  plugins_sentry_client_config_ts_o5jjUx2pcO,
  plugins_seo_manager_ts_WfCGakEskd,
  plugins_theme_ts_zupea9J243,
  plugins_token_ts_NCWuir2H2e,
  plugins_vee_validate_ts_KcKlKmvCrJ,
  plugins_vimeo_client_ts_BFAm9LGErL,
  plugins_y_rest_client_ts_ouPNQxsruT,
  plugins_ya_membership_ts_oqaQS7MApt,
  plugins_z_apollo_ts_kElVZx10q2,
  plugins_z_app_version_watcher_ts_cqHyqBLNWq,
  plugins_z_auth_ts_BqBYggCFPK,
  plugins_z_graphql_client_ts_5cZ6csbNhp
]