import { useDataVisualisationStore } from '@/stores/data-visualization'

export default defineNuxtRouteMiddleware((to, _from) => {
  const dataVisualisationStore = useDataVisualisationStore()
  const DATA_VISUALISATION = 'data-visualisation'
  if (to.name !== DATA_VISUALISATION && _from.name !== DATA_VISUALISATION)
    dataVisualisationStore.cleanArticles()
  if (
    to.name === DATA_VISUALISATION &&
    !dataVisualisationStore.$state.displayedArticles.length
  ) {
    if (process.client) window.scrollTo(0, 0)
  }

  /**
   * Validate that we are not on the home page and remove the slash (/)
   * if the url has it at the end.
   */
  if (process.client && to.name !== 'index' && to.path.endsWith('/')) {
    const params = to.fullPath.split('?')[1]
    let route = to.path.slice(0, -1)

    if (params) route = `${route}?${params}`

    return navigateTo(route, { redirectCode: 301 })
  }
})
