import decodeJWT from 'jwt-decode'
import { v4 as uuidv4 } from 'uuid'
import { Token } from '@/models/auth'
import {
  JWT_ACCESS_TOKEN,
  REFRESH_TOKEN,
  BACKEND_TOKEN_GENERATOR,
  BACKEND_VERSION,
} from '@/constants/auth'
import { useAuthStore } from '@/stores/auth'

export default defineNuxtPlugin(({ $cookies, $config }) => {
  /**
   * Memory cache, so we don't go to the server every time we need
   * to check the backend version.
   */
  let memoryBackendVersionCache = null

  /**
   * Refresh the jwt token using the existing refresh token inside
   * the cookies
   *
   * @returns new jwt token
   */
  const refreshTokenRequest = async (): Promise<string> => {
    const refreshToken = $cookies.get(REFRESH_TOKEN)

    const fetchResult = await fetch($config.graphqlEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `
          mutation refreshToken {
            refreshJwtAuthToken(input: { clientMutationId: "${uuidv4()}", jwtRefreshToken: "${refreshToken}" }) {
              authToken
            }
          }
        `,
      }),
    }).then((res) => res.json())

    const newToken = fetchResult.data.refreshJwtAuthToken.authToken
    $cookies.set(JWT_ACCESS_TOKEN, newToken)

    return newToken
  }

  /**
   * Get the existing JWT token if exists and is valid, otherwise
   * get a new one using the refresh token
   */
  const getToken = async (): Promise<string | undefined> => {
    const token = $cookies.get(JWT_ACCESS_TOKEN)
    const refreshToken = $cookies.get(REFRESH_TOKEN)

    if (isTokenValid(token)) return token

    if (!refreshToken) return undefined

    const newToken = await refreshTokenRequest()

    return newToken
  }

  /**
   * Decode the token to get its information
   */
  const decodeToken = (token: string): Token | undefined => {
    let decoded

    try {
      decoded = decodeJWT(token)
    } catch (e) {}

    return decoded
  }

  /**
   * Check if the token is valid or not to be used on the request
   */
  const isTokenValid = (token: string): boolean => {
    const decodedToken = decodeToken(token)

    return Boolean(decodedToken && Date.now() < decodedToken.exp * 1000)
  }

  /**
   * Get the backend version.
   *
   * @returns
   */
  const getBackendVersion = async (): Promise<string> => {
    if (memoryBackendVersionCache) {
      return memoryBackendVersionCache
    }
    const response: { version: string } = await $fetch(
      `${$config.apiRestEndpoint}/backend-version`,
    )
    const version = response.version
    memoryBackendVersionCache = version
    return version
  }

  /**
   * Get backend version from the cookie
   */
  const getBackendVersionCookieValue = (): string | undefined => {
    return $cookies.get(BACKEND_VERSION)
  }

  /**
   * Check if the local version of the backend is the same as the one
   * in the cookie.
   *
   * @returns boolean
   */
  const isBackendVersionValid = async () => {
    const backendVersion = await getBackendVersion()
    const backendVersionCookieValue = getBackendVersionCookieValue()

    return backendVersionCookieValue === backendVersion
  }

  /**
   * Check if the backend who emits the token is the same as the current backend.
   * Otherwise, the token is not valid anymore and cannot be refreshed by the refresh
   * token.
   *
   * @returns void
   */
  const validateCookieState = async () => {
    const refreshToken = $cookies.get(REFRESH_TOKEN)

    // We check the refresh token instead of the auth token, because the auth token
    // expires after 15 minutes. The refresh token expires after 1 year.
    if (typeof window === 'undefined' || !refreshToken) return

    // Backend who generates the current auth and refresh token
    const backendTokenGenerator = localStorage.getItem(BACKEND_TOKEN_GENERATOR)

    const isBackendValid = await isBackendVersionValid()
    if (backendTokenGenerator !== $config.backendUrl || !isBackendValid) {
      $cookies.removeCookies()
      useAuthStore().clear()
    }
  }

  const getAuthorizationHeader = async (): Promise<{}> => {
    await validateCookieState()

    let options = {}
    const token = await getToken()

    if (isTokenValid(token)) {
      options = Object.assign({}, options, {
        headers: { Authorization: `Bearer ${token}` },
      })
    }

    return options
  }

  const refreshTokenExists = () => $cookies.get(REFRESH_TOKEN)

  return {
    provide: {
      token: {
        getAuthorizationHeader,
        getToken,
        decodeToken,
        isTokenValid,
        getBackendVersion,
        refreshTokenExists,
        refreshTokenRequest,
      },
    },
  }
})
