import isObject from 'lodash/isObject'
import uniqBy from 'lodash/uniqBy'

interface Meta {
  hid: string
  name?: string
  property?: string
  content: string | null
}

interface Link {
  hid: string
  rel: string
  href: string
}

export default defineNuxtPlugin(({ $config, $router, $cloudinary }) => {
  const HOSTNAME = $config.metaTagBaseUrl
  const SOCIAL_NETWORK_IMAGE = `${HOSTNAME}/benchmark-social-logo.png`

  const DEFAULT_META: Meta[] = [
    {
      hid: 'description',
      name: 'description',
      content:
        'Benchmark Source is your gateway to a wealth of analysis, data visualisations, video streaming and presentation downloads for the supply chains of the energy transition.',
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content:
        'Benchmark Source is your gateway to a wealth of analysis, data visualisations, video streaming and presentation downloads for the supply chains of the energy transition.',
    },
    {
      hid: 'twitter:description',
      property: 'twitter:description',
      content:
        'Benchmark Source is your gateway to a wealth of analysis, data visualisations, video streaming and presentation downloads for the supply chains of the energy transition.',
    },
    { hid: 'og:type', property: 'og:type', content: 'website' },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: 'Benchmark Source',
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: SOCIAL_NETWORK_IMAGE,
    },
    {
      hid: 'og:image:secure_url',
      property: 'og:image:secure_url',
      content: SOCIAL_NETWORK_IMAGE,
    },
    {
      hid: 'twitter:card',
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    { hid: 'twitter:site', name: 'twitter:site', content: '@benchmarkmin' },
    {
      hid: 'twitter:creator',
      name: 'twitter:creator',
      content: '@benchmarkmin',
    },
    { hid: 'twitter:site', name: 'twitter:site', content: '@benchmarkmin' },
    {
      hid: 'twitter:image',
      name: 'twitter:image',
      content: SOCIAL_NETWORK_IMAGE,
    },
  ]

  const TITLE_PREFIX = ' | Benchmark Source'
  const titleBuilder = (title: string) => `${title}${TITLE_PREFIX}`

  /**
   * Method to generate the seo properties required
   * by each page or post
   *
   * @param {Object} metas - Meta tags you want to add or override, for example: {title, link, etc}
   * @param {Object} seoProperties - Seo properties like {opengraphTitle, twitterCard, twitterTitle, ...and so on}
   * @param {Object} additionalData - Article related information
   * @returns Nuxt head() method required the following structure: {title, meta: [], link: []}
   */
  const generateSeoInfo = (
    metas: Record<string, any>,
    seoProperties: any = {},
    additionalData: Record<string, any> = {},
  ): any => {
    const title = titleBuilder(metas.title || 'Home')
    const canonicalValue = generateCanonicalLink()
    let seoMetadata = [
      { hid: 'og:title', property: 'og:title', content: title },
    ]
    /** more info: https://ogp.me/#type_article */
    if (metas?.author) {
      const seoMetadataAuthor = [
        {
          hid: 'og:author',
          property: 'og:author',
          content: metas.author,
        },
        {
          hid: 'og:article:author',
          property: 'og:article:author',
          content: metas.author,
        },
      ]
      seoMetadata = [...seoMetadata, ...seoMetadataAuthor]
    }
    if (metas?.publishDate) {
      const seoMetadataPublishedDate = [
        {
          hid: 'og:publish_date',
          property: 'og:publish_date',
          content: metas?.publishDate,
        },
        {
          hid: 'og:article:published_time',
          property: 'og:article:published_time',
          content: metas?.publishDate,
        },
      ]
      seoMetadata = [...seoMetadata, ...seoMetadataPublishedDate]
    }
    return {
      meta: uniqBy(
        [
          ...buildMetas(seoProperties, additionalData),
          ...[
            {
              hid: 'og:url',
              property: 'og:url',
              content: canonicalValue[0].href,
            },
          ],
          ...seoMetadata,
          ...DEFAULT_META,
        ],
        'hid',
      ),
      link: canonicalValue,
      ...metas,
      title,
    }
  }

  const sanitizeSeoProperties = (
    seoProperties: Record<string, any>,
    additionalData: Record<string, any>,
  ): Record<string, any> => {
    if (seoProperties.opengraphTitle) {
      // YoastSEO add "- Benchmark" as a suffix to the title. Eg, "Home - Benchmark"
      // We use the convention of adding " | Benchmark Source" as a suffix to the title
      seoProperties.opengraphTitle = seoProperties.opengraphTitle.replace(
        ' - Benchmark',
        TITLE_PREFIX,
      )
    }

    seoProperties.twitterImage = $cloudinary.resize(
      seoProperties.twitterImage?.mediaItemUrl ||
        seoProperties.opengraphImage?.mediaItemUrl ||
        additionalData.featuredImage,
      'twitter-card',
    )

    seoProperties.opengraphImage = $cloudinary.resize(
      seoProperties.opengraphImage?.mediaItemUrl ||
        additionalData.featuredImage,
      'linkedin-card',
    )

    // Validate that the description of the open graph exists and assign that value to the twitter description and page description
    if (seoProperties.opengraphDescription) {
      seoProperties.twitterDescription = seoProperties.opengraphDescription
      seoProperties.description = seoProperties.opengraphDescription
    }

    if (seoProperties.opengraphImage) {
      // If there is an opengraph:image, it should be an opengraph:secure:image as well.
      // That's why we append the opengraphImage:secure_url to the seoProperties
      seoProperties['opengraphImage:secure_url'] = seoProperties.opengraphImage
    }
    return seoProperties
  }

  const getSeoProperties = (
    seoProperties: Record<string, any>,
  ): Array<string> => {
    return Object.keys(seoProperties).filter((key) => !!seoProperties[key])
  }

  const getSeoValue = (
    seoProperties: Record<string, any>,
    key: keyof typeof seoProperties,
  ): string | null => {
    // Only twitterImage and opengraphImage are objects and
    // has a property called mediaItemUrl that is the image url
    // for the page, post, or custom post type
    if (isObject(seoProperties[key])) {
      return seoProperties[key].mediaItemUrl
    }
    return seoProperties[key]
  }

  const generateCanonicalLink = (): Link[] => {
    return [
      {
        hid: 'canonical',
        rel: 'canonical',
        href: `${HOSTNAME}${$router?.currentRoute?.value?.fullPath}`,
      },
    ]
  }

  const buildMetas = (
    seoProperties: Record<string, any>,
    additionalData: Record<string, any>,
  ): Meta[] => {
    const allSeoProperties = sanitizeSeoProperties(
      seoProperties,
      additionalData,
    )

    return getSeoProperties(allSeoProperties).map(
      (key): Meta => ({
        hid: formatSeoPropertyName(key),
        [isOpengraphProperty(key) ? 'property' : 'name']:
          formatSeoPropertyName(key),
        content: getSeoValue(seoProperties, key),
      }),
    )
  }

  const isOpengraphProperty = (propertyName: string): boolean => {
    return propertyName.startsWith('opengraph')
  }

  const isTwitterProperty = (propertyName: string): boolean => {
    return propertyName.startsWith('twitter')
  }

  const formatSeoPropertyName = (propertyName: string): string => {
    const firstUpperLetterIndex = propertyName
      .split('')
      .findIndex((letter) => /[A-Z]/.test(letter))
    const name = propertyName.substr(firstUpperLetterIndex).toLowerCase()

    if (isOpengraphProperty(propertyName)) {
      return `og:${name}`
    }

    return isTwitterProperty(propertyName) ? `twitter:${name}` : propertyName
  }

  return {
    provide: {
      seoManager: {
        generateSeoInfo,
      },
    },
  }
})
