import { useAppStore } from '@/stores/app'

export default defineNuxtPlugin(({ $config }) => {
  const app = useAppStore()
  app.setCurrentVersion($config.commitSha)
  setInterval(async () => {
    // We check the version of the app only if the tab is active
    if (
      $config.isAppVersionWatcherEnabled &&
      typeof document !== 'undefined' &&
      !document.hidden
    ) {
      try {
        const response: { version: string } = await $fetch(
          '/api/current-app-version',
        )
        app.setRemoteVersion(response.version)
      } catch (e) {}
    }
  }, 10000)
})
