import { stringify } from 'query-string'
import {
  FetchResponse,
  RequestOptions,
  SearchQueryParams,
} from '@/models/request'
import {
  getAdaptedMagazines,
  getAdaptedPresentations,
  getAdaptedPresentation,
  getRestAdaptedContents,
  getAdaptedSpecialIssues,
  getAdaptedSpecialIssue,
} from '@/utils/adapters'
import { Company, Content, Event, MagazineDetailRaw } from '@/models/content'
import { MembershipStatus } from '@/models/membership'
import {
  ChangePasswordRequest,
  SetPasswordRequest,
  ForgotPasswordRequest,
} from '@/models/auth'
import { delayed } from '@/utils/functions'
import { ChartData } from '@/models/statistics'
import news from '@/fixtures/news.json'
import { HttpClientProxy } from '@/utils/http'

export default defineNuxtPlugin(({ $config, $token }) => {
  function request<TType>({
    path,
    method,
    baseUrl,
    body,
    params,
  }: RequestOptions) {
    const url = `${baseUrl || $config.baseUrl}${path}`
    const httpProxy = new HttpClientProxy($fetch, $token, {
      maxNumberOfRetries: 1,
    })
    return httpProxy.request<TType>(url, method, body, params)
  }

  const includeNonPublished = $config.isPreviewSite

  return {
    provide: {
      restClient: {
        request,

        getLatestNews() {
          return delayed<Content[]>(news.latest, 300)
        },

        async getMagazines({
          itemsPerPage,
          categories,
        }: {
          itemsPerPage?: number
          categories?: string[]
        } = {}) {
          const params: { [key: string]: any } = {}

          if (itemsPerPage) {
            params.page_limit = itemsPerPage
          }

          if (includeNonPublished) {
            params['post_statuses[]'] = ['draft', 'future', 'publish']
          }

          if (categories?.length) {
            params.category = categories
          }

          const options = {
            path: `/downloads/magazines`,
            method: 'post',
            baseUrl: $config.apiRestEndpoint,
            params,
          }

          const { data, total } = await request<{
            total: number
            data: Object[]
          }>(options)

          return {
            total,
            data: getAdaptedMagazines(data),
          }
        },

        getMagazineDetailById(id: string) {
          return request<MagazineDetailRaw>({
            path: `/downloads/magazine/details/${id}`,
            method: 'post',
            baseUrl: $config.apiRestEndpoint,
          })
        },

        async getPresentations({
          itemsPerPage,
          categories,
          getTotal,
        }: {
          itemsPerPage?: number
          categories?: string[]
          getTotal?: boolean
        } = {}) {
          const params: { [key: string]: any } = {}

          if (itemsPerPage) {
            params.page_limit = itemsPerPage
          }

          if (getTotal) {
            params.total = true
          }

          if (categories?.length) {
            params['categories[]'] = categories
          }

          if (includeNonPublished) {
            params['post_statuses[]'] = ['draft', 'future', 'publish']
          }

          const options = {
            path: `/downloads/presentations`,
            method: includeNonPublished ? 'post' : 'get',
            baseUrl: $config.apiRestEndpoint,
            params,
          }

          if (getTotal) {
            const { total, data } = await request<{
              total: number
              data: Object[]
            }>(options)

            return {
              total,
              data: getAdaptedPresentations(data),
            }
          }

          const data = await request<{
            data: Object[]
          }>(options)

          return { data: getAdaptedPresentations(data) }
        },

        async getPresentationsByQueryString({
          queryStrings,
        }: { queryStrings?: string } = {}) {
          const statuses = includeNonPublished
            ? '&post_statuses[]=draft&post_statuses[]=future&post_statuses[]=publish'
            : ''
          const query =
            queryStrings ||
            '?page_limit=12&page_offset=0&show_pagination=true&use_pagination=true&show_total=true'
          const options = {
            path: `/downloads/presentations${query}${statuses}`,
            method: 'post',
            baseUrl: $config.apiRestEndpoint,
          }

          const { data, total, urls } = await request<{
            data: Object[]
            total: number
            urls: { next?: string; prev?: string; first: string; last: string }
          }>(options)
          return {
            data: getAdaptedPresentations(data),
            total,
            query: urls.next ?? '',
            hasNextPage: !!urls.next,
          }
        },

        async getPresentationById(id: string) {
          return getAdaptedPresentation(
            await request<Company[]>({
              path: `/downloads/presentations/${id}`,
              method: 'post',
              baseUrl: $config.apiRestEndpoint,
            }),
          )
        },

        getCompanies() {
          return request<Company[]>({
            path: '/downloads/companies',
            method: 'get',
            baseUrl: $config.apiRestEndpoint,
          })
        },

        getEvents() {
          return request<Event[]>({
            path: '/downloads/events',
            method: 'get',
            baseUrl: $config.apiRestEndpoint,
          })
        },

        getPresentationDates() {
          return request<string[]>({
            path: '/downloads/presentation-dates',
            method: 'get',
            baseUrl: $config.apiRestEndpoint,
          })
        },

        getFile(id: string, category: string) {
          return request<Blob>({
            path: `/downloads/category/${category}/${id}`,
            method: 'post',
            baseUrl: $config.apiRestEndpoint,
          })
        },

        getTickerIndexes() {
          return request<Object>({
            path: '/prices/v2/ticker',
            method: 'post',
            baseUrl: $config.apiRestEndpoint,
          })
        },

        /**
         * new endpoint of capacities data indicators
         */
        getCapacitiesDataIndicators() {
          return request<Object>({
            path: '/ma-data/v2/indicators',
            method: 'get',
            baseUrl: $config.apiRestEndpoint,
          })
        },

        async getSearchResult(searchParams: SearchQueryParams) {
          const queryString = stringify(searchParams, {
            arrayFormat: 'bracket',
          })

          return getRestAdaptedContents(
            await request({
              path: `/search?${queryString}`,
              method: 'post',
              baseUrl: $config.apiRestEndpoint,
            }),
          )
        },

        checkMembershipStatus() {
          return request<boolean>({
            path: '/membership/status/category/membership',
            method: 'post',
            baseUrl: $config.apiRestEndpoint,
          })
        },

        checkTrialMembershipStatus() {
          return request<boolean>({
            path: '/membership/status/category/trial',
            method: 'post',
            baseUrl: $config.apiRestEndpoint,
          })
        },

        changePassword(data: ChangePasswordRequest) {
          return request<FetchResponse>({
            path: '/password/change',
            method: 'put',
            baseUrl: $config.apiRestEndpoint,
            body: data,
          })
        },

        setPassword(data: SetPasswordRequest) {
          return request<FetchResponse>({
            path: '/password/set-password',
            method: 'post',
            baseUrl: $config.apiRestEndpoint,
            body: data,
          })
        },

        requestResetPasswordLink(data: ForgotPasswordRequest) {
          return request<FetchResponse>({
            path: '/password/reset-password',
            method: 'post',
            baseUrl: $config.apiRestEndpoint,
            body: data,
          })
        },

        getGraphsData(params: { ids: string[] }) {
          return request<ChartData[]>({
            path: '/graphs',
            method: 'post',
            baseUrl: $config.apiRestEndpoint,
            body: params,
          })
        },

        getMembershipStatuses(params: { slugs: string[] }) {
          return request<MembershipStatus[]>({
            path: '/memberships/info',
            method: 'post',
            baseUrl: $config.apiRestEndpoint,
            body: params,
          })
        },

        getVideoFilters() {
          return request<{ events: string[] }>({
            path: '/videos/filters',
            method: 'get',
            baseUrl: $config.apiRestEndpoint,
          })
        },

        async getIndexesByProduct(productSlug: string) {
          const response = await request<{
            $metadata: object
            data: any
          }>({
            path: `/prices/v2/${productSlug}/index`,
            method: 'post',
            baseUrl: $config.apiRestEndpoint,
          })
          return {
            summary: response?.data?.summary ?? [],
            detail: response?.data?.detail ?? [],
          }
        },

        async getSpecialIssues({
          itemsPerPage,
          categories,
        }: {
          itemsPerPage?: number
          categories?: string[]
        } = {}) {
          const params: { [key: string]: any } = {}

          if (itemsPerPage) {
            params.page_limit = itemsPerPage
          }

          if (includeNonPublished) {
            params['post_statuses[]'] = ['draft', 'future', 'publish']
          }

          if (categories?.length) {
            params.category = categories
          }

          const options = {
            path: `/downloads/category/special-issues`,
            method: 'post',
            baseUrl: $config.apiRestEndpoint,
            params,
          }

          const data = await request<{
            data: Object[]
          }>(options)
          return {
            data: getAdaptedSpecialIssues(data),
          }
        },

        async getSpecialIssue(id: string) {
          const params: { [key: string]: any } = {}

          if (includeNonPublished) {
            params['post_statuses[]'] = ['draft', 'future', 'publish']
          }

          const options = {
            path: `/downloads/special-issues/${id}`,
            method: 'post',
            baseUrl: $config.apiRestEndpoint,
            params,
          }

          const data = await request<{
            data: Object
          }>(options)
          return {
            data: getAdaptedSpecialIssue(data),
          }
        },
      },
    },
  }
})
