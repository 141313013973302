import { default as _47opt_47buildhome_47repo_47pages_47about_46vueMeta } from "/opt/buildhome/repo/pages/about.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47account_46vueMeta } from "/opt/buildhome/repo/pages/account.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47article_47_91slug_93_46vueMeta } from "/opt/buildhome/repo/pages/article/[slug].vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47category_47_91category_93_47_91_91subcategory_93_93_46vueMeta } from "/opt/buildhome/repo/pages/category/[category]/[[subcategory]].vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47contact_46vueMeta } from "/opt/buildhome/repo/pages/contact.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47cookies_46vueMeta } from "/opt/buildhome/repo/pages/cookies.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47data_45visualisation_46vueMeta } from "/opt/buildhome/repo/pages/data-visualisation.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47downloads_47magazine_46vueMeta } from "/opt/buildhome/repo/pages/downloads/magazine.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47downloads_47presentations_47_91id_93_46vueMeta } from "/opt/buildhome/repo/pages/downloads/presentations/[id].vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47downloads_47presentations_47index_46vueMeta } from "/opt/buildhome/repo/pages/downloads/presentations/index.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47downloads_47special_45issues_47_91id_93_46vueMeta } from "/opt/buildhome/repo/pages/downloads/special-issues/[id].vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47downloads_47special_45issues_47index_46vueMeta } from "/opt/buildhome/repo/pages/downloads/special-issues/index.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47index_46vueMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47login_46vueMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47newsletter_46vueMeta } from "/opt/buildhome/repo/pages/newsletter.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47privacy_46vueMeta } from "/opt/buildhome/repo/pages/privacy.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47request_45a_45trial_46vueMeta } from "/opt/buildhome/repo/pages/request-a-trial.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47reset_45password_46vueMeta } from "/opt/buildhome/repo/pages/reset-password.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47search_47category_47_91category_93_46vueMeta } from "/opt/buildhome/repo/pages/search/category/[category].vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47search_47index_46vueMeta } from "/opt/buildhome/repo/pages/search/index.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47search_47tag_47_91tag_93_46vueMeta } from "/opt/buildhome/repo/pages/search/tag/[tag].vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47set_45password_46vueMeta } from "/opt/buildhome/repo/pages/set-password.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47terms_46vueMeta } from "/opt/buildhome/repo/pages/terms.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47video_47_91_91category_93_93_46vueMeta } from "/opt/buildhome/repo/pages/video/[[category]].vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47video_47watch_47_91slug_93_46vueMeta } from "/opt/buildhome/repo/pages/video/watch/[slug].vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47viewer_47pdf_47_91_91id_93_93_47guest_46vueMeta } from "/opt/buildhome/repo/pages/viewer/pdf/[[id]]/guest.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47viewer_47pdf_47_91_91id_93_93_47index_46vueMeta } from "/opt/buildhome/repo/pages/viewer/pdf/[[id]]/index.vue?macro=true";
export default [
  {
    name: _47opt_47buildhome_47repo_47pages_47about_46vueMeta?.name ?? "about",
    path: _47opt_47buildhome_47repo_47pages_47about_46vueMeta?.path ?? "/about",
    file: "/opt/buildhome/repo/pages/about.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47about_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47about_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47about_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47account_46vueMeta?.name ?? "account",
    path: _47opt_47buildhome_47repo_47pages_47account_46vueMeta?.path ?? "/account",
    file: "/opt/buildhome/repo/pages/account.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47account_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47account_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47account_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/account.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47article_47_91slug_93_46vueMeta?.name ?? "article-slug",
    path: _47opt_47buildhome_47repo_47pages_47article_47_91slug_93_46vueMeta?.path ?? "/article/:slug",
    file: "/opt/buildhome/repo/pages/article/[slug].vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47article_47_91slug_93_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47article_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47article_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/article/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47category_47_91category_93_47_91_91subcategory_93_93_46vueMeta?.name ?? "category-category-subcategory",
    path: _47opt_47buildhome_47repo_47pages_47category_47_91category_93_47_91_91subcategory_93_93_46vueMeta?.path ?? "/category/:category/:subcategory?",
    file: "/opt/buildhome/repo/pages/category/[category]/[[subcategory]].vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47category_47_91category_93_47_91_91subcategory_93_93_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47category_47_91category_93_47_91_91subcategory_93_93_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47category_47_91category_93_47_91_91subcategory_93_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/category/[category]/[[subcategory]].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.name ?? "contact",
    path: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.path ?? "/contact",
    file: "/opt/buildhome/repo/pages/contact.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47cookies_46vueMeta?.name ?? "cookies",
    path: _47opt_47buildhome_47repo_47pages_47cookies_46vueMeta?.path ?? "/cookies",
    file: "/opt/buildhome/repo/pages/cookies.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47cookies_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47cookies_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47cookies_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47data_45visualisation_46vueMeta?.name ?? "data-visualisation",
    path: _47opt_47buildhome_47repo_47pages_47data_45visualisation_46vueMeta?.path ?? "/data-visualisation",
    file: "/opt/buildhome/repo/pages/data-visualisation.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47data_45visualisation_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47data_45visualisation_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47data_45visualisation_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/data-visualisation.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47downloads_47magazine_46vueMeta?.name ?? "downloads-magazine",
    path: _47opt_47buildhome_47repo_47pages_47downloads_47magazine_46vueMeta?.path ?? "/downloads/magazine",
    file: "/opt/buildhome/repo/pages/downloads/magazine.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47downloads_47magazine_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47downloads_47magazine_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47downloads_47magazine_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/downloads/magazine.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47downloads_47presentations_47_91id_93_46vueMeta?.name ?? "downloads-presentations-id",
    path: _47opt_47buildhome_47repo_47pages_47downloads_47presentations_47_91id_93_46vueMeta?.path ?? "/downloads/presentations/:id",
    file: "/opt/buildhome/repo/pages/downloads/presentations/[id].vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47downloads_47presentations_47_91id_93_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47downloads_47presentations_47_91id_93_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47downloads_47presentations_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/downloads/presentations/[id].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47downloads_47presentations_47index_46vueMeta?.name ?? "downloads-presentations",
    path: _47opt_47buildhome_47repo_47pages_47downloads_47presentations_47index_46vueMeta?.path ?? "/downloads/presentations",
    file: "/opt/buildhome/repo/pages/downloads/presentations/index.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47downloads_47presentations_47index_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47downloads_47presentations_47index_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47downloads_47presentations_47index_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/downloads/presentations/index.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47downloads_47special_45issues_47_91id_93_46vueMeta?.name ?? "downloads-special-issues-id",
    path: _47opt_47buildhome_47repo_47pages_47downloads_47special_45issues_47_91id_93_46vueMeta?.path ?? "/downloads/special-issues/:id",
    file: "/opt/buildhome/repo/pages/downloads/special-issues/[id].vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47downloads_47special_45issues_47_91id_93_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47downloads_47special_45issues_47_91id_93_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47downloads_47special_45issues_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/downloads/special-issues/[id].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47downloads_47special_45issues_47index_46vueMeta?.name ?? "downloads-special-issues",
    path: _47opt_47buildhome_47repo_47pages_47downloads_47special_45issues_47index_46vueMeta?.path ?? "/downloads/special-issues",
    file: "/opt/buildhome/repo/pages/downloads/special-issues/index.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47downloads_47special_45issues_47index_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47downloads_47special_45issues_47index_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47downloads_47special_45issues_47index_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/downloads/special-issues/index.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.name ?? "index",
    path: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.path ?? "/",
    file: "/opt/buildhome/repo/pages/index.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47index_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47login_46vueMeta?.name ?? "login",
    path: _47opt_47buildhome_47repo_47pages_47login_46vueMeta?.path ?? "/login",
    file: "/opt/buildhome/repo/pages/login.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47login_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47login_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47login_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47newsletter_46vueMeta?.name ?? "newsletter",
    path: _47opt_47buildhome_47repo_47pages_47newsletter_46vueMeta?.path ?? "/newsletter",
    file: "/opt/buildhome/repo/pages/newsletter.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47newsletter_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47newsletter_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47newsletter_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/newsletter.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47privacy_46vueMeta?.name ?? "privacy",
    path: _47opt_47buildhome_47repo_47pages_47privacy_46vueMeta?.path ?? "/privacy",
    file: "/opt/buildhome/repo/pages/privacy.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47privacy_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47privacy_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47privacy_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47request_45a_45trial_46vueMeta?.name ?? "request-a-trial",
    path: _47opt_47buildhome_47repo_47pages_47request_45a_45trial_46vueMeta?.path ?? "/request-a-trial",
    file: "/opt/buildhome/repo/pages/request-a-trial.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47request_45a_45trial_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47request_45a_45trial_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47request_45a_45trial_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/request-a-trial.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47reset_45password_46vueMeta?.name ?? "reset-password",
    path: _47opt_47buildhome_47repo_47pages_47reset_45password_46vueMeta?.path ?? "/reset-password",
    file: "/opt/buildhome/repo/pages/reset-password.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47reset_45password_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47reset_45password_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47reset_45password_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47search_47category_47_91category_93_46vueMeta?.name ?? "search-category-category",
    path: _47opt_47buildhome_47repo_47pages_47search_47category_47_91category_93_46vueMeta?.path ?? "/search/category/:category",
    file: "/opt/buildhome/repo/pages/search/category/[category].vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47search_47category_47_91category_93_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47search_47category_47_91category_93_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47search_47category_47_91category_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/search/category/[category].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47search_47index_46vueMeta?.name ?? "search",
    path: _47opt_47buildhome_47repo_47pages_47search_47index_46vueMeta?.path ?? "/search",
    file: "/opt/buildhome/repo/pages/search/index.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47search_47index_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47search_47index_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47search_47index_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47search_47tag_47_91tag_93_46vueMeta?.name ?? "search-tag-tag",
    path: _47opt_47buildhome_47repo_47pages_47search_47tag_47_91tag_93_46vueMeta?.path ?? "/search/tag/:tag",
    file: "/opt/buildhome/repo/pages/search/tag/[tag].vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47search_47tag_47_91tag_93_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47search_47tag_47_91tag_93_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47search_47tag_47_91tag_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/search/tag/[tag].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47set_45password_46vueMeta?.name ?? "set-password",
    path: _47opt_47buildhome_47repo_47pages_47set_45password_46vueMeta?.path ?? "/set-password",
    file: "/opt/buildhome/repo/pages/set-password.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47set_45password_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47set_45password_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47set_45password_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/set-password.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47terms_46vueMeta?.name ?? "terms",
    path: _47opt_47buildhome_47repo_47pages_47terms_46vueMeta?.path ?? "/terms",
    file: "/opt/buildhome/repo/pages/terms.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47terms_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47terms_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47terms_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47video_47_91_91category_93_93_46vueMeta?.name ?? "video-category",
    path: _47opt_47buildhome_47repo_47pages_47video_47_91_91category_93_93_46vueMeta?.path ?? "/video/:category?",
    file: "/opt/buildhome/repo/pages/video/[[category]].vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47video_47_91_91category_93_93_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47video_47_91_91category_93_93_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47video_47_91_91category_93_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/video/[[category]].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47video_47watch_47_91slug_93_46vueMeta?.name ?? "video-watch-slug",
    path: _47opt_47buildhome_47repo_47pages_47video_47watch_47_91slug_93_46vueMeta?.path ?? "/video/watch/:slug",
    file: "/opt/buildhome/repo/pages/video/watch/[slug].vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47video_47watch_47_91slug_93_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47video_47watch_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47video_47watch_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/video/watch/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47viewer_47pdf_47_91_91id_93_93_47guest_46vueMeta?.name ?? "viewer-pdf-id-guest",
    path: _47opt_47buildhome_47repo_47pages_47viewer_47pdf_47_91_91id_93_93_47guest_46vueMeta?.path ?? "/viewer/pdf/:id?/guest",
    file: "/opt/buildhome/repo/pages/viewer/pdf/[[id]]/guest.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47viewer_47pdf_47_91_91id_93_93_47guest_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47viewer_47pdf_47_91_91id_93_93_47guest_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47viewer_47pdf_47_91_91id_93_93_47guest_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/viewer/pdf/[[id]]/guest.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47viewer_47pdf_47_91_91id_93_93_47index_46vueMeta?.name ?? "viewer-pdf-id",
    path: _47opt_47buildhome_47repo_47pages_47viewer_47pdf_47_91_91id_93_93_47index_46vueMeta?.path ?? "/viewer/pdf/:id?",
    file: "/opt/buildhome/repo/pages/viewer/pdf/[[id]]/index.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47viewer_47pdf_47_91_91id_93_93_47index_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47viewer_47pdf_47_91_91id_93_93_47index_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47viewer_47pdf_47_91_91id_93_93_47index_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/viewer/pdf/[[id]]/index.vue").then(m => m.default || m)
  }
]