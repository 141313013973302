import gql from 'graphql-tag'

const buildArticleWithSeo = ({ slug }: { slug: string }) => {
  return gql`
    query getArticleWithSeo {
      article: membership(id: "${slug}", idType: SLUG) {
        title
        date
        featuredImage {
          node {
            mediaItemUrl
            caption
          }
        }
        memberships {
          authorInfo {
            company
            fullName
            jobTitle
          }
        }
        seo {
          twitterTitle
          twitterDescription
          twitterImage {
            mediaItemUrl
          }
          opengraphTitle
          opengraphDescription
          opengraphType
          opengraphImage {
            mediaItemUrl
          }
        }
      }
    }
  `
}

export default buildArticleWithSeo
