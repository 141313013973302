import gql from 'graphql-tag'
import articleFields from './fragments/article-fields.gql'

export default () => gql`
  query getArticleDetail($slug: ID!) {
    article: membership(id: $slug, idType: SLUG) {
      ...articleFields
    }
  }
  ${articleFields}
`
