import gql from 'graphql-tag'
import articleFields from './fragments/article-fields.gql'
import videoFields from './fragments/video-fields.gql'
import { PUBLISHED_POST_STATES } from '~/constants/content'

const buildArticlesAndVideosQuery = ({ statuses = PUBLISHED_POST_STATES }) => {
  return gql`
    query getArticlesAndVideos(
      $first: Int
      $excluded: [ID]
    ) {
      articles: memberships(
        first: $first
        where: {
          stati: [${statuses}]
          notIn: $excluded,
          taxQuery: {
            taxArray: [
              {
                terms: ["public"]
                taxonomy: CATEGORY
                operator: NOT_IN
                field: SLUG
              }
            ]
          }
        }
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ... articleFields
        }
      }
      videos(
        first: $first
        where: { 
          stati: [${statuses}]
          orderby: { field: DATE, order: DESC }
          showOnlyFeaturedPosts: true
        }
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ... videoFields
        }
      }
    }
    ${videoFields}
    ${articleFields}
  `
}

export default buildArticlesAndVideosQuery
