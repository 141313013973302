export const PUBLIC_GRAPHQL_ENDPOINT =
  'https://benchmark-www-staging.stellate.sh'

export const TIME_FORMAT = 'MMM dd, yyyy'

export const FORMIDABLE_FIELD_WEBSITE_URL = 'website/url'

export const CONSENT_KEY = '__consent_accepted'
export const TRIAL_EXPIRED_MODAL_KEY = '__do_not_show_trial_expired_modal'
export const NO_SOURCE_MODAL_KEY = '__do_not_show_no_source_subscription_modal'

export const HUBSPOT_SCRIPT = {
  src: '//js-eu1.hs-scripts.com/25724218.js',
  id: 'hs-script-loader',
  type: 'text/javascript',
  defer: true,
}

export const HOTJAR_SCRIPT = {
  id: 'hotjar-script',
  type: 'text/javascript',
  defer: true,
  children: `
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3347534,hjsv:6};
        a=o.getElementsByTagName("head")[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
`,
}
