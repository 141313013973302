import gql from 'graphql-tag'
import videoFields from './fragments/video-fields.gql'
import { PUBLISHED_POST_STATES } from '~/constants/content'

const buildVideosByCategoriesQuery = ({ statuses = PUBLISHED_POST_STATES }) => {
  return gql`
    query getVideosByCategories(
      $first: Int!
      $after: String
      $categories: [String]!
      $events: [String]
      $search: String
    ) {
      videos(
        first: $first
        after: $after
        where: {
          stati: [${statuses}]
          search: $search
          orderby: { field: DATE, order: DESC }
          taxQuery: {
            taxArray: [
              {
                terms: $categories
                taxonomy: CATEGORY
                operator: IN
                field: SLUG
              }
            ]
          }
          metaQuery: {
            relation: AND
            metaArray:[{
              key: "event",
              values: $events,
              compare: IN
            }]
          }
        }
      ) {
        pageInfo {
          hasNextPage
          endCursor
          total
        }
        nodes {
          ... videoFields
        }
      }
    }
    ${videoFields}
  `
}

export default buildVideosByCategoriesQuery
