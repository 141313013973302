export default defineNuxtPlugin(() => {
  const IMAGE_SIZES = {
    'home-hero-mobile': ['c_scale', 'h_300', 'w_600', 'c_fill', 'g_auto'],
    'home-hero': ['c_scale', 'h_600', 'w_1200', 'c_fill', 'g_auto'],
    'home-hero-hd': ['c_scale', 'h_600', 'w_2400', 'c_fill', 'g_auto'],
    'home-hero-4k': ['c_scale', 'h_600', 'w_4000', 'c_fill', 'g_auto'],
    'magazine-hero': ['w_500', 'c_scale', 'c_fill', 'g_auto'],
    'magazine-thumbnail': ['h_470', 'c_fill', 'g_auto'],
    'presentation-thumbnail': ['w_570', 'c_fill', 'g_auto'],
    'vertical-card-thumbnail': ['w_300', 'h_200', 'c_fill', 'g_auto'],
    'vertical-video-card-thumbnail': ['w_500', 'c_fill', 'g_auto'],
    'horizontal-card-thumbnail': ['w_600', 'c_fill', 'g_auto', 'ar_16:9'],
    'archive-card-thumbnail': ['w_370', 'h_208', 'c_fill', 'g_auto'],
    'membership-thumbnail': ['w_600', 'c_fill', 'g_auto'],
    'featured-category-thumbnail': ['w_1100', 'c_fill', 'g_auto', 'ar_16:9'],
    'featured-chart-thumbnail': ['c_scale'],
    'featured-thumbnail': ['w_445', 'h_460', 'c_fill', 'g_auto'],
    'featured-video-thumbnail': ['w_1000', 'h_600', 'c_fill', 'g_auto'],
    'featured-latest-news-thumbnail': ['w_1000', 'h_600', 'c_fill', 'g_auto'],
    'article-detail-cover': ['w_700', 'h_400', 'c_fill', 'g_auto'],
    'video-cover': ['w_auto', 'h_600', 'c_fill', 'g_auto'],
    'data-v-vertical-thumbnail': ['w_380', 'h_364', 'c_fill', 'g_auto'],
    'twitter-card': ['w_800', 'h_418', 'c_fill', 'g_auto'],
    'linkedin-card': ['w_1200', 'h_627', 'c_fill', 'g_auto'],
    'special-issues-card': ['w_340', 'c_fill', 'g_auto'],
    'special-issues-section': ['w_800', 'c_fill', 'g_auto'],
  }

  type ImageSize = keyof typeof IMAGE_SIZES

  /**
   * Check if an url is a cloudinary url
   *
   * @param {string} imageUrl
   * @returns
   */
  const isCloudinaryUrl = (imageUrl: string | undefined) => {
    return imageUrl?.includes('res.cloudinary.com')
  }

  /**
   * Some images come with transformation params, in order to make a successful resize
   * we need to remove those. E.g.
   * https://res.cloudinary.com/benchmarkminerals/images/w_2560,h_1362/f_auto,q_auto/v1654631307/kualo_qa_media_library/Shanghai-lockdown/Shanghai-lockdown.jpg?_i=AA
   *
   * That url will become:
   * https://res.cloudinary.com/benchmarkminerals/images/f_auto,q_auto/v1654631307/kualo_qa_media_library/Shanghai-lockdown/Shanghai-lockdown.jpg?_i=AA
   *
   * @param {string} imageUrl
   * @returns string
   */
  const removeExistingTransformationParams = (imageUrl) => {
    return imageUrl
      .split('/')
      .filter(
        (segment) =>
          !['w_', 'h_', 'q_', 'f_'].some((param) => segment.startsWith(param)),
      )
      .join('/')
  }

  const getSizeParams = (size: ImageSize) => {
    const defaultParams = ['f_auto', 'q_auto']

    return [...defaultParams, ...IMAGE_SIZES[size]]
  }

  /**
   * Add parameters to resize the image
   *
   * @param {string} imageUrl
   * @param {string} size
   * @returns
   */
  const resize = (imageUrl, size: ImageSize) => {
    if (!isCloudinaryUrl(imageUrl) || !IMAGE_SIZES[size]) {
      return imageUrl
    }

    const sizeParams = getSizeParams(size)
    imageUrl = removeExistingTransformationParams(imageUrl).replace(
      '/images',
      `/images/${sizeParams.join(',')}`,
    )

    return imageUrl
  }

  return {
    provide: {
      cloudinary: {
        resize,
      },
    },
  }
})
