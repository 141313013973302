import numeral from 'numeral'

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export function titleCase(str) {
  return str
    .split(' ')
    .map((w) => capitalize(w))
    .join(' ')
}

export function toTitleCase(str, separator = /[-_]/) {
  return str.split(separator).map(capitalize).join(' ')
}

export function snakeCaseToCamelCase(str) {
  return str
    .split('_')
    .map((name, i) => {
      if (i > 0) return capitalize(name)

      return name
    })
    .join('')
}

export function parseMagazineTitle(magazineTitle) {
  const [quarter, titleIssue] = magazineTitle.split(' | ')
  const [title, issue] = titleIssue.split(' - ')

  return [quarter, title, issue]
}

export function toNumberWithCommas(x, withDecimals: boolean = false) {
  return numeral(x).format(withDecimals ? '0,0.0' : '0,0')
}

export function makeTwitterSharableLink(url: string, title: string): string {
  return `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    url,
  )}&text=${encodeURI(title)}`
}

export function makeLinkedinSharableLink(url: string, title: string): string {
  return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
    url,
  )}&title=${encodeURI(title)}`
}

export function removeAnchorWithHref(text: string): string {
  const anchorTagRegexInit = /<a[^>]*href="[^"]*"[^>]*>/gi
  const sanitizeTextInit = text?.replaceAll(anchorTagRegexInit, '')
  const anchorTagRegexEnd = /<\/a>/gi
  return sanitizeTextInit?.replaceAll(anchorTagRegexEnd, '')
}
